<template>
	<div class="partial partial--my-applications my-applications">
		<div class="partial__heading">
			<ion-icon name="apps" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">My Resources</h1>
		</div>

		<div class="partial__body">
			<list-my-applications :applications="myApplications" :user="me" :access="true" />
		</div>

		<p>&nbsp;</p>
		<br />
		<div class="partial__heading">
			<ion-icon name="apps" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Available Resources</h1>
		</div>

		<div class="partial__body">
			<list-my-applications :applications="otherApplications" />
		</div>
	</div>
</template>

<script>
import { ApplicationStatus } from '@/lib/enums'
import { invert } from 'lodash'
import ListMyApplications from '@/components/ListMyApplications'

export default {
	name: 'partialMyApplications',
	components: {
		ListMyApplications,
	},
	metaInfo: () => ({
		title: 'My Resources',
	}),
	asyncData: ({ store }) => {
		return store.dispatch('application/list')
	},
	data: () => ({
		staticApplications: [
			{
				icon: 'glasses-outline',
				name: 'Banker to Banker',
				description: 'Get the latest data, strategies, and tactics that can save your bank resources and provide performance-enhancing ideas to keep you ahead of the competition.',
				url: 'https://southstatecorrespondent.com/category/banker-to-banker/',
				resource: true,
			},
			{
				icon: 'chatbubbles-outline',
				name: 'Market Commentary',
				description: 'Market commentary and research articles on the overall economy as well as opportunities and risks in the fixed income markets.',
				url: 'https://southstatecorrespondent.com/category/market-insights-commentary/',
				resource: true,
			},
			{
				icon: 'trail-sign-outline',
				name: 'Market Rates',
				description: 'Get the latest derivative and brokered CD rates',
				url: 'https://southstatecorrespondent.com/market-rates/',
				resource: true,
			},
			{
				icon: 'mic-outline',
				name: 'Community Bank Podcast',
				description: 'The podcast dedicated to helping community bankers grow themselves, their team, and their profits.',
				url: 'https://southstatecorrespondent.com/podcasts/',
				resource: true,
			}
		]
	}),
	computed: {
		me() {
			return this.$store.getters['user/me']
		},
		applications() {
			const applications = this.$store.getters['application/applications']
			return applications.filter(a => a.status !== invert(ApplicationStatus).Inactive ).map(a => {
				try {
					a.logo = require(`@/assets/logos/${a.appId}.svg`)
				} catch (error) {
					a.logo = require(`@/assets/logos/blank.png`)
				}
				return a
			})
		},
		myApplications() {
			const me = this.$store.getters['user/me']

			me.permissions = me.permissions || {}
			const myApplications = this.applications.filter(a => me.permissions[a.appId])
			return [ ...myApplications, ...this.staticApplications]
		},
		otherApplications() {
			const me = this.$store.getters['user/me']

			me.permissions = me.permissions || {}
			return this.applications.filter(a => !me.permissions[a.appId])
		},
	},
}
</script>

<style scoped lang="scss">

</style>
