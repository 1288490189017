<template>
	<nav class="main-nav" role="navigation">
		<div class="main-nav__title">
			<router-link :to="{ name: 'main' }"><img src="~@/assets/images/correspondent-logo--white-text.png" alt="Correspondent Division" width="200" /></router-link>
		</div>

		<div class="main-nav__mobile" :class="{'main-nav__mobile--open': menuOpen}">
			<ion-icon name="menu-outline" class="main-nav__mobile-button" size="large" @click="menuToggle(null)"></ion-icon>

			<div class="main-nav__mobile-items">
				<router-link
					class="main-nav__item"
					active-class="main-nav__item--active"
					:exact="item.link.name === 'main'"
					:to="item.link"
					:key="`user_${key}`"
					v-for="(item, key) in userNav"
					@click.native="menuToggle(false)"
				>
					<ion-icon :name="item.icon" v-if="item.icon"></ion-icon>
					<span class="main-nav__item-title">{{ item.title }}</span>
				</router-link>

				<div class="main-nav__sep"></div>

				<router-link
					class="main-nav__item"
					active-class="main-nav__item--active"
					:to="item.link"
					:key="`dashboard_${key}`"
					v-for="(item, key) in dashNav"
					@click.native="menuToggle(false)"
				>
					<ion-icon :name="item.icon" v-if="item.icon"></ion-icon>
					<span class="main-nav__item-title">{{ item.title }}</span>
				</router-link>


				<div class="main-nav__sep" v-if="adminNav.length"></div>

				<router-link
					class="main-nav__item"
					active-class="main-nav__item--active"
					:to="item.link"
					:key="`admin_${key}`"
					v-for="(item, key) in adminNav"
					@click.native="menuToggle(false)"
				>
					<ion-icon :name="item.icon" v-if="item.icon"></ion-icon>
					<span class="main-nav__item-title">{{ item.title }}</span>
				</router-link>

				<router-link
					class="main-nav__item"
					active-class="main-nav__item--active"
					:to="item.link"
					:key="`super_${key}`"
					v-for="(item, key) in superNav"
					@click.native="menuToggle(false)"
				>
					<ion-icon :name="item.icon" v-if="item.icon"></ion-icon>
					<span class="main-nav__item-title">{{ item.title }}</span>
				</router-link>

			</div>
		</div>

	</nav>
</template>

<script>
export default {
	name: 'mainNav',
	computed: {
		userNav() {
			return this.$store.getters['navigation/menu']('user')
		},
		dashNav() {
			return this.$store.getters['navigation/menu']('dashboard')
		},
		adminNav() {
			return this.$store.getters['navigation/menu']('admin')
		},
		superNav() {
			return this.$store.getters['navigation/menu']('super')
		},
		menuOpen() {
			return this.$store.getters['navigation/menuOpen']
		},
	},
	methods: {
		menuToggle(status) {
			return this.$store.dispatch('navigation/menuToggle', status)
		}
	}
}
</script>

<style scoped lang="scss">
@use '@material/elevation';

.main-nav {
	@include modules.gutter('padding');

	background-color: modules.color_('primary');
	display: flex;
	justify-content: space-between;
	flex-direction: row;

	@include modules.media-query('desktop') {
		flex-direction: column;
		justify-content: initial;
		//flex-direction: row-reverse;
	}

	@include modules.media-query-min-width('desktop-xs') {
		@include modules.gutter('padding-left', .5);
		@include modules.gutter('padding-right', .5);
	}

	@include modules.media-query-min-width('desktop-sm') {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	&__title {
		@include modules.gutter('margin-bottom', 0);

		@include modules.media-query('desktop') {
			@include modules.gutter('margin-bottom');
		}
	}

	ion-icon {
		@include modules.fontSize(24px);
	}

	&__item {
		align-items: center;
		color: modules.color_('text', 'medium');
		display: flex;
		flex-direction: row;
		@include modules.fontSize(16px);
		font-weight: 600;
		line-height: 1.375;
		margin-bottom: 2.5em;
		margin-top: 2.5em;
		text-decoration: none;

		span {
			border-bottom: 1px solid transparent;
			transition: all 150ms linear;

			&:hover {
				color: modules.color_('text', 'accent');
			}
		}

		&--active {
			color: modules.color_('primary', 'text');

			span {
				&:hover {
					border-bottom-color: modules.color_('text', 'accent');
					color: modules.color_('primary', 'text');
				}
			}

		}

		&-title {
			margin-left: 1em;
			white-space: pre;
		}
	}

	&__sep {
		@include modules.gutter('margin-bottom');
		@include modules.gutter('margin-top');
		background-color: #203155;
		display: block;
		height: 2px;
		width: 1.5rem;
	}

	&__mobile {
		$mobile: &;

		position: relative;
		top: 5px;
		text-align: right;

		&-button {
			display: block;
			color: #fff;
		}

		&-items {
			@include modules.gutter('padding');
			@include elevation.elevation(10);
			background-color: modules.color_('background', 'dark');
			opacity: 0;
			position: absolute;
			pointer-events: none;
			right: 0;
			transform: translateY(-20%);
			transition: all 150ms modules.$ease-out;
			z-index: 9;

			#{$mobile}--open & {
				opacity: 1;
				pointer-events: all;
				transform: translateY(0);
			}
		}

		@include modules.media-query('desktop') {
			&-button {
				color: modules.color_('primary', 'text');
				display: none;
			}

			&-items {
				@include modules.gutter('padding', 0);
				@include elevation.elevation(0);
				opacity: initial;
				background-color: inherit;
				opacity: 1;
				transform: none;
				transition: none;
				position: relative;
				pointer-events: initial;
				z-index: initial;
			}
		}
	}
}
</style>
